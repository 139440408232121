'use client';

import React from 'react';
import { useEffect, useState } from 'react';

import IntercomClient, { hide, update } from '@intercom/messenger-js-sdk';
import { usePathname } from 'next/navigation';

export default function Intercom() {
    IntercomClient({
        app_id: 'l57hi6e1',
    });

    const pathname = usePathname();
    const [, setChanges] = useState(0);

    useEffect(() => {
        setChanges(prev => prev + 1);
        update({ last_request_at: parseInt(new Date().getTime() / 1000) });
        // TEMP hardcoded until new alerts
        if (!['/contact', '/visit-us/creamery'].includes(pathname)) {
            hide();
        }
    }, [pathname]);

    return null;
}

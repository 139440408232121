'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGeolocation } from 'react-use';

import mapboxClient from '@mapbox/mapbox-sdk';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import PropTypes from 'prop-types';

export const LocationContext = createContext({
    locationFallback: {},
    isLoaded: false,
});

const MAPBOX_TOKEN =
    'pk.eyJ1IjoiaGF1c2xhIiwiYSI6ImNrbHN0dHRzNjA1d24yd25xZWhka2xmMm8ifQ.C3nu0o6XmETb877Vj6tjfQ';

export const useLocation = () => {
    const { locationFallback, isLoaded: fallbackLoaded } =
        useContext(LocationContext);

    const [location, setLocation] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const geolocation = useGeolocation();

    useEffect(() => {
        if (fallbackLoaded && geolocation.loading === true) {
            setLocation(locationFallback);
        }
        const getReverseGeocoding = async () => {
            await new Promise(resolve => {
                if (geolocation.loading === false && !isLoaded) {
                    resolve();
                }
            });
            const geocoded = await mapboxGeocoding(
                mapboxClient({ accessToken: MAPBOX_TOKEN })
            )
                .reverseGeocode({
                    query: [geolocation.longitude, geolocation.latitude],
                    types: ['postcode'],
                })
                .send();

            const match = geocoded.body.features[0];

            const cityResult = match?.context.find(item =>
                item?.id?.includes('place')
            ).text;
            const stateResult = match?.context.find(item =>
                item?.id?.includes('region')
            );

            const stateDisplay =
                stateResult?.short_code.split('-')[0] === 'US'
                    ? stateResult?.short_code.split('-')[1]
                    : stateResult?.text;

            const countryResult = match?.context.find(item =>
                item?.id?.includes('country')
            ).text;

            setLocation(
                geolocation.latitude
                    ? {
                          postalCode: match?.text,
                          coordinates: match?.center,
                          city: cityResult,
                          state: stateDisplay,
                          country: countryResult,
                          accuracy: 'precise',
                      }
                    : locationFallback
            );

            setIsLoaded(true);
        };

        if (fallbackLoaded) {
            getReverseGeocoding();
        }
    }, [fallbackLoaded, geolocation, isLoaded, locationFallback]);

    return [location, setLocation];
};

export const LocationProvider = ({ children }) => {
    const [renderedLocation, setUserLocation] = useState(null);

    useEffect(() => {
        async function locate() {
            try {
                const response = await fetch(
                    'https://d2k6913brarspg.cloudfront.net/geo'
                );
                setUserLocation({
                    viewerCountry: response.headers.get(
                        'cloudfront-viewer-country'
                    ),
                    viewerCountryRegion: response.headers.get(
                        'cloudfront-viewer-country-region'
                    ),
                    viewerCity: response.headers.get('cloudfront-viewer-city'),
                    viewerLat: response.headers.get(
                        'cloudfront-viewer-latitude'
                    ),
                    viewerLong: response.headers.get(
                        'cloudfront-viewer-longitude'
                    ),
                });
            } catch (e) {
                console.error(e);
            }
        }
        locate();
    }, []);

    const [locationFallback, setLocationFallback] = useState(renderedLocation);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (renderedLocation) {
            const {
                viewerCity: city,
                viewerCountry: country,
                viewerCountryRegion: state,
                viewerLat: latitude,
                viewerLong: longitude,
            } = renderedLocation;

            setLocationFallback({
                city,
                country,
                state,
                coordinates: [longitude, latitude],
                accuracy: 'fallback',
            });

            setIsLoaded(true);
        }
    }, [renderedLocation]);

    return (
        <LocationContext.Provider value={{ locationFallback, isLoaded }}>
            {children}
        </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.shape({
        viewerCity: PropTypes.string,
        viewerCountry: PropTypes.string,
        viewerCountryRegion: PropTypes.string,
        viewerLat: PropTypes.string,
        viewerLong: PropTypes.string,
    }),
};

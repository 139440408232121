import(/* webpackMode: "eager" */ "/vercel/path0/app/(main)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/globals/Alerts/Alerts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/globals/Footer/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/globals/Header/Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/globals/Intercom/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/globals/PageLoader/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/one-trust.scss");
